import { Link } from "react-router-dom";
import { useState } from "react"

import { useAuthContext } from '../hooks/useAuthContext'
import { useLogout } from '../hooks/useLogout'


import { ReactComponent as SettingsIcon } from "../assets/settings-icon.svg";
import { ReactComponent as BarChartIcon } from "../assets/bar-chart-icon.svg";
import { ReactComponent as BarChartIcon2 } from "../assets/bar-chart-2.svg";
import { ReactComponent as ArrowIcon } from "../assets/corner-down-right.svg";
import { ReactComponent as MagnifyIcon } from "../assets/search-icon.svg";
import { ReactComponent as BookIcon } from "../assets/book-open-icon.svg";
import { ReactComponent as PageIcon } from "../assets/page-nav-icon.svg";
import { ReactComponent as ClipboardIcon } from "../assets/clipboard-icon.svg";
import { ReactComponent as LogOutIcon } from "../assets/log-out-icon.svg";
import { ReactComponent as HelpIcon } from "../assets/help-circle.svg";
import { ReactComponent as SensorIcon } from "../assets/sensor-icon-grey.svg";
import { ReactComponent as KeywordIcon } from "../assets/kw-icon.svg";
import { ReactComponent as DescriptionIcon } from "../assets/description-icon.svg";
import logo from "../assets/deer-32.png";

export default function DashboardSidebar() {
    const { user } = useAuthContext()
    const { logout } = useLogout()
    const [showMobileSideBar, setShowMobileSideBar] = useState(false)
    let pathName = window.location.pathname.slice(1)

    // if no user return blank element
    if (!user) {
        return <></>
    }

    return (
        <div className={`flex-none w-full lg:w-64 lg:flex-auto lg:static   ${showMobileSideBar ? 'fixed top-0 left-0 right-0 bottom-0 bg-white z-40' : ''}`}>
            <div className='w-full lg:w-64 shrink-0 border-r-2 lg:min-h-[70vh] lg:h-full'>
                <ul id="sidebar-container" className="list-none w-full sticky top-0 flex flex-col pb-8">
                    <li className="flex flex-nowrap justify-between items-center">
                        <Link to="/" className={`flex p-4 items-center gap-1 text-grey `}>
                            <span className='w-8'><img src={logo} className="w-full" /></span>
                            <span aria-label="Home" className="text-gray-500 visited:text-gray-500 font-bold font-['Arial'] tracking-wide">Sales<span className="text-rose-300">Doe</span></span>
                        </Link>
                        <div onClick={() => setShowMobileSideBar(!showMobileSideBar)} className="lg:hidden p-4 cursor-pointer">
                            <div className="bg-gray-700 w-8 h-1"></div>
                            <div className="w-8 h-1"></div>
                            <div className="bg-gray-700 w-8 h-1"></div>
                            <div className="w-8 h-1"></div>
                            <div className="bg-gray-700 w-8 h-1"></div>
                        </div>
                    </li>

                    {/* start of research tools */}
                    <li className={`text-xl font-bold text-rose-400 tracking-wide px-4 pt-5 pb-1 ${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`} style={{ fontVariant: "all-small-caps" }}>Product Research</li>


                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <Link to="/listing-overview" className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-100 ${pathName == "listing-overview" ? "bg-gray-100" : ""}`}>
                            <PageIcon />
                            <span>Listing Overview</span>
                        </Link>
                    </li>


                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <Link to="/keyword-overview" className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-100 ${pathName == "keyword-overview" ? "bg-gray-100" : ""}`}>
                            <KeywordIcon />
                            <span>Keyword Overview</span>
                        </Link>
                    </li>

                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <Link to="/keyword-research" className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-100 ${pathName == "keyword-research" ? "bg-gray-100" : ""}`}>
                            <MagnifyIcon />
                            <span>Keyword Research</span>
                        </Link>
                    </li>


                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <Link to="/shop-overview" className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-100 ${pathName == "shop-overview" ? "bg-gray-100" : ""}`}>
                            <BookIcon />
                            <span>Shop Overview</span>
                        </Link>
                    </li>


                    {/* start of seo tools */}
                    <li className={`text-xl font-bold text-rose-400 tracking-wide px-4 pt-5 pb-1 ${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`} style={{ fontVariant: "all-small-caps" }}>Analyze & Optimize</li>

                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <Link to="/competition" className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-50 ${pathName == "competition" ? "bg-zinc-200" : ""} ${pathName == "competition/settings" ? "bg-gray-100" : ""} `}>
                            <span className='w-6'><BarChartIcon /></span>
                            <span>Competition Monitor</span>
                        </Link>
                        {pathName.includes("competition") &&
                            <Link to="/competition/analytics" className={`flex text-small py-3 px-6 mb-2 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-50 ${pathName == "competition/analytics" ? "bg-gray-200" : ""} ${pathName == "competition" ? "bg-zinc-100" : ""}`}>
                                <span className='w-6 h-4'><ArrowIcon className="h-full" /></span>
                                <span>Analytics</span>
                            </Link>
                        }
                    </li>


                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <Link to="/listing-optimizer" className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-100 ${pathName == "listing-optimizer" ? "bg-gray-100" : ""}`}>
                            <DescriptionIcon />
                            <span>Listing Optimizer</span>
                        </Link>
                    </li>

                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <Link to="/listing-tracker" className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-100 ${pathName == "listing-tracker" ? "bg-gray-100" : ""}`}>
                            <ClipboardIcon />
                            <span>Listing Tracker</span>
                        </Link>
                    </li>

                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <Link to="/etsy-rank-checker" className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-100 ${pathName == "etsy-rank-checker" ? "bg-gray-100" : ""}`}>
                            <BarChartIcon2 />
                            <span>Rank Checker</span>
                        </Link>
                    </li>

                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <Link to="/sensor" className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-100 ${pathName == "sensor" ? "bg-gray-100" : ""}`}>
                            <SensorIcon />
                            <span>Sensor</span>
                        </Link>
                    </li>


                    {/* start of utilities */}
                    <li className={`text-xl font-bold text-rose-400 tracking-wide px-4 pt-5 pb-1 ${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`} style={{ fontVariant: "all-small-caps" }}>Other</li>
                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <Link to="/account" className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-100 ${pathName == "account" ? "bg-gray-100" : ""}`}>
                            <SettingsIcon />
                            <span>Account</span>
                        </Link>
                    </li>
                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <Link to="/help-center" className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-100`}>
                            <HelpIcon />
                            <span>Help Center</span>
                        </Link>
                    </li>
                    <li className={`${showMobileSideBar ? "list-item" : "hidden"}  lg:!list-item`}>
                        <div onClick={logout} className={`flex px-4 py-3 items-center gap-4 text-grey visited:text-grey hover:bg-neutral-100 cursor-pointer`}>
                            <LogOutIcon />
                            <span>Logout</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}