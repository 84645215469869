import Footer from "./Footer"
import Nav from "./Nav"
import BioBox from "./BioBox"
import MainDashboardContent from "./MainDashboardContent"
import MainContent from "./MainContent"


export default function BlogPost({ children }) {
    return (
      <>
      <Nav />
      <MainContent>
        <MainDashboardContent thin>
          <section>
            <div className="w-full mx-1 my-8 border rounded-md bg-white py-4 px-8 text-lg">
              {children}

              <BioBox />
            </div>
          </section>
        </MainDashboardContent>
      </MainContent>
      <Footer />
    </>
      )
  }
  