import { useState } from "react"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"

import { useAuthContext } from '../hooks/useAuthContext'
import { usePlanContext } from '../hooks/usePlanContext'
import { useLogout } from "../hooks/useLogout"

import Footer from "../components/Footer"
import DashboardSidebar from "../components/DashboardSidebar"
import MainDashboardContent from "../components/MainDashboardContent"
import MainContent from "../components/MainContent"
import { ReactComponent as HelpIcon } from "../assets/help-circle.svg";
import { ReactComponent as Checkmark } from "../assets/check.svg";
import { ReactComponent as Warning } from "../assets/alert-triangle.svg";
import { ReactComponent as Xicon } from "../assets/xicon.svg";

const iconsMap = {
  "complete": <Checkmark className="w-6 h-6 text-emerald-700 shrink-0" />,
  "warning": <Warning className="w-6 h-6 text-amber-500 shrink-0" />,
  "incomplete": <Xicon className="w-6 h-6 text-rose-500 shrink-0" />,
}

const messageMap = {
  'keywordInTitle': {
    'complete': "Your title includes the exact keyword in the title.",
    'incomplete': "Your title should include the exact keyword you're targeting in the title if this is the primary keyword you want to rank for."
  },
  'keywordInTitlePartial': {
    'complete': "Your title includes the individual keywords in the title, just not the exact phrase, which is OK for secondary keywords.",
    'incomplete': "Your title does not contain the individual words in the keyword that you're targeting."
  },
  'keywordNearStart': {
    'complete': "Your title includes the keyword near the start of the title.",
    'incomplete': "Your title should include at least part of the keyword that you're targeting near the start of the title."
  },
  'titleLength': {
    'complete': "Your title is between 70-100 characters.",
    'incomplete': "Your title is shorter than recommended, titles between 70-100 characters perform best.",
    'warning': "While a long title isn't a negative ranking factor, consider testing a shorter one. In general, titles between 70-100 characters perform best."
  },
  'keywordInTags': {
    'complete': "Your tags include the exact keyword.",
    'incomplete': "Your tags should include the exact keyword you're targeting if this is a high priority keyword to rank for."
  },
  'keywordInTagsPartial': {
    'complete': "Your tags include the individual keywords in the tags, just not one with the exact phrase. This is OK for secondary keywords, but reconsider if it's appropriate to have a tag with the exact keyword",
    'incomplete': "Your tags do not contain the individual words in the keyword that you're targeting."
  },
  'tagsUsed': {
    'complete': "You're using all 13 tags.",
    'incomplete': "It's recommended to use all 13 tags available to maximize search impressions.",
  },
  'totalRepeatedWords': {
    'complete': "You don't have too many repeated words in your tags.",
    'incomplete': "It's recommended to avoid using repeated words in your tags. Use more unique related keywords to increase search impressions.",
  },
  'numberOfImages': {
    'complete': "You have 6+ images in your listing.",
    'incomplete': "It's recommended to have at least 6 images in your listing to address potential buyer concerns.",
  },
  'numberOfVideos': {
    'complete': "You included a product video in your listing.",
    'incomplete': "It's recommended to include a product video in order to let potential customers see your product in action.",
  },
  'reviewCountScore': {
    'complete': "Your listing has a high amount of reviews.",
    'incomplete': "It's recommended to prioritize getting more reviews for this listing.",
    'warning': "While you do have some reviews, more reviews would likely improve conversion rates.",
  },
  'reviewRating': {
    'complete': "Your listing has a high average review rating.",
    'incomplete': "Your average review rating is low, which will decrease your conversion rates.",
    'warning': "Your listing has a relatively low review rating. If you can increase it a bit, it will likely increase your conversion rates."
  },
  'recentReviews': {
    'complete': "Great, your listing has a good number of recent reviews.",
    'incomplete': "Your listing has a low number of recent reviews.",
    'warning': "Your listing would likely benefit from getting more recent reviews."
  }
}

const DiagnosticItemBoolean = ({ item, value, border = true }) => {
  const state = value ? "complete" : "incomplete"
  return (
    <div className={`flex flex-nowrap gap-4 py-4 px-2 w-full ${border ? "border-b" : "border-0"}`}>
      <div className="text-neutral-600">{iconsMap[state]}</div>
      <div className="text-neutral-800">{messageMap[item][state]}</div>
    </div>
  )
}


const DiagnosticItem = ({ item, incomplete, warning, complete, border = true }) => {
  let state;
  if (incomplete) {
    state = "incomplete"
  } else if (warning) {
    state = "warning"
  } else if (complete) {
    state = "complete"
  }
  return (
    <div className={`flex flex-nowrap gap-4 py-4 px-2 w-full ${border ? "border-b" : "border-0"}`}>
      <div className="text-neutral-600">{iconsMap[state]}</div>
      <div className="text-neutral-800">{messageMap[item][state]}</div>
    </div>
  )
}

const ScoreCircle = ({ score }) => {
  const radius = 45;
  const circumference = 2 * Math.PI * radius;
  const progress = Math.max(0.3, score / 100) * circumference;

  // Color transition from red (low) to green (high)
  const getColor = (score) => {
    const red = Math.max(0, 255 - (score * 2.55));
    const green = Math.min(255, score * 2.55);

    if (score > 60) return `rgb(${red}, ${green}, 100)`;
    if (score > 40) return `rgb(${red}, ${green}, 200)`;
    return `rgb(255, ${green}, 50)`;
  };

  return (
    <div className="flex items-center justify-center">
      <svg width="120" height="120" viewBox="0 0 100 100">
        {/* Background Circle */}
        <circle
          cx="50"
          cy="50"
          r={radius}
          stroke="#ddd"
          strokeWidth="10"
          fill="none"
        />
        {/* Progress Circle */}
        <circle
          cx="50"
          cy="50"
          r={radius}
          stroke={getColor(score)}
          strokeWidth="10"
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
          strokeLinecap="round"
          transform="rotate(-90 50 50)"
        />
        {/* Score Text */}
        <text x="50" y="55" textAnchor="middle" fontSize="24" fill="#333">
          {score}
        </text>
      </svg>
    </div>
  );
};


const ListingOptimizer = () => {
  const { user } = useAuthContext()
  const { activePlan, listingOptimizerCount, listingOptimizerLimit } = usePlanContext()
  const { logout } = useLogout()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [keywordInput, setKeywordInput] = useState('')
  const [listingInput, setListingInput] = useState('')
  const [reportKeyword, setReportKeyword] = useState('')


  const [listingData, setListingData] = useState({})
  const [scoreData, setScoreData] = useState({})


  const handleSubmit = e => {
    e.preventDefault();

    if (listingInput.length > 0) {
      setLoading(true)
      setError('')

      let listingId;
      // check if they just entered the id alone
      if (listingInput.includes('/')) {

        // remove any parameters
        let sanitizedListing = (listingInput.split('?')[0]).split('/')
        listingId = sanitizedListing[sanitizedListing.length - 2]

      } else {
        listingId = listingInput
      }

      // sanitize kw by removing any extra spaces between or after words and converting to lower case
      let keyword = keywordInput.trim().replace(/\s+/g, ' ')

      if (keyword.length < 3) {
        setError('Keyword must be at least 3 characters long.')
        setLoading(false)
        return
      }

      setListingData({})
      setScoreData({})

      fetch(`/api/listings/optimizer?listing_id=${listingId.trim()}&keyword=${keyword}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText)
          }

          return res.json()
        })
        .then(data => {
          const { listingData, scoreData, error } = data
          setLoading(false)
          if (error) {
            if (error == "Request is not authorized") {
              logout()
            }
            setError(error)
          } else {
            setError('')
            setListingData(listingData)
            setScoreData(scoreData)
            setReportKeyword(keywordInput)
            setListingInput('')
            setKeywordInput('')
          }

        })
        .catch(err => {
          console.log('error', err)
          setError(err.message)
          setLoading(false)
        })
    }

  };


  const ratingToLabel = (rating, maxRating) => {
    const percent = (rating / maxRating) * 100

    if (percent > 70) {
      return <span className="text-emerald-600 font-bold">Excellent</span>
    } else if (percent > 50) {
      return <span className="text-orange-400 font-bold">Average</span>
    } else {
      return <span className="text-rose-600 font-bold">Needs Improvement</span>
    }

  }

  return (
    <>
      <Helmet>
        <title>Listing Optimizer - SalesDoe</title>
      </Helmet>

      <MainContent>
        <DashboardSidebar />
        <MainDashboardContent>
          <span className="flex gap-2 items-center">
            <h1 className="text-2xl text-gray-600 tracking-wide" style={{ fontVariant: "all-small-caps" }}>Listing Optimizer</h1>
            <span className="w-4 pt-1.5 text-blue-700">
              <Link to="/help-center/listing-optimizer" target="_blank" >
                <HelpIcon className="w-full" />
              </Link>
            </span>
          </span>
          {user && !activePlan && <p className="text-sm">You've used <strong>{Math.min(listingOptimizerCount, listingOptimizerLimit)} out of {listingOptimizerLimit} reports</strong> this month. <Link to="/pricing" className="text-blue-600" target="_blank">Upgrade</Link> to get more reports.</p>
          }
          <p className="text-sm py-2">Enter a listing and a keyword that you'd like it to rank for when an Etsy user searches for a product. The listing optimizer will then assess how well your listing is optimized for that specific keyword based on best practices. Use these scores to spot any big issues, but don't obsess over the specific numbers and ratings too much.</p>


          <>
            <div className="p-4 border bg-white my-4 rounded">
            {user && !activePlan && listingOptimizerCount >= listingOptimizerLimit && <p className="text-sm text-red-600">Your monthly search limit has been reached. Please upgrade to continue using the listing optimizer.</p>}

              <form onSubmit={handleSubmit} className="flex gap-x-12 gap-y-4 flex-wrap items-center">
                <div className="max-w-[600px] w-full min-w-0 self-stretch  flex flex-col justify-between break-words">
                  <label className="text-sm py-2 break-all">Enter the full URL of an Etsy listing or the listing id. e.g. <span className="italic text-emerald-800 font-bold">https://www.etsy.com/listing/931102669/blackberry-vanilla-soy-wax-candle</span> or <span className="italic text-emerald-800 font-bold">931102669</span>.</label>
                  <input value={listingInput} onChange={e => setListingInput(e.target.value)} type="text" placeholder="Etsy listing URL or ID..." className="rounded-lg mt-1 mb-1 max-w-[600px] w-full min-w-0"></input>
                </div>

                <div className="w-96 max-w-full self-stretch flex flex-col justify-between">
                  <label className="text-sm py-2">Enter the main keyword that you're targeting (max 20 characters). e.g. <span className="italic text-emerald-800 font-bold">soy wax candle</span>.</label>
                  <input value={keywordInput} onChange={e => setKeywordInput((e.target.value).slice(0, 20))} type="text" placeholder="Keyword..." className="rounded-lg mt-1 mb-1 w-64 max-w-full"></input>
                </div>

                <input type="submit" value={loading ? "Loading..." : "Submit"} disabled={loading || listingOptimizerCount >= listingOptimizerLimit} className="cursor-pointer text-emerald-800 border-emerald-400 bg-emerald-200 hover:bg-emerald-300 w-full rounded-lg w-full"></input>

                {error && <div className="error">{error}</div>}

              </form>

            </div>


            {listingData.url &&
              <div className="border-b pt-8 pb-2 break-words">
                <h2 className="text-2xl truncate w-full" title={listingData.title}>Listing: <span className="text-rose-600"><a href={listingData.url} target="_blank">{listingData.title}</a></span>
                </h2>
                <h2 className="text-xl w-full mt-2" title={reportKeyword}>Target keyword: <span className="text-blue-600">{reportKeyword}</span>
                </h2>

              </div>
            }

            {scoreData && scoreData.score &&
              <div>
                {/* Overall score */}
                <div className="flex flex-wrap gap-4">
                  <div className="px-8 py-2 border bg-white mt-2 mb-2 rounded-md w-full sm:w-72 shrink-0 grow flex flex-col">
                    <h3 className="text-rose-500 text-3xl pt-4 pb-2 font-bold text-center" style={{ fontVariant: "all-small-caps" }}>Overall Rating</h3>
                    <div className="pb-4 pt-2 group text-gray-600 text-3xl text-center">
                      <ScoreCircle score={scoreData.score} />
                    </div>
                  </div>
                </div>

                {/* Title score */}
                <div className="flex flex-wrap gap-4">
                  <div className="px-8 py-2 border bg-white mt-2 mb-2 rounded-md w-full sm:w-72 shrink-0 grow flex flex-col">
                    <div className="text-center">
                      <h3 className="text-neutral-700 text-2xl pt-4 pb-2 font-bold" style={{ fontVariant: "all-small-caps" }}>Title Optimization</h3>
                      <div className="pb-4 pt-2 group text-neutral-600 text-3xl">
                        {ratingToLabel(scoreData.title.score, 10)}
                      </div>
                      <p className="text-neutral-600 text-sm px-4">Your title is one of the biggest factors in whether or not your listing shows up in an Etsy user's search results.</p>
                    </div>

                    <div>
                      <h4 className="text-neutral-700 text-xl pt-4 pb-2 font-bold" style={{ fontVariant: "all-small-caps" }}>Key Aspects</h4>

                      <DiagnosticItemBoolean item="keywordInTitle" value={scoreData.title.keywordInTitle} />
                      {!scoreData.title.keywordInTitle &&
                        <DiagnosticItemBoolean item="keywordInTitlePartial" value={scoreData.title.keywordInTitlePartial} />
                      }
                      <DiagnosticItemBoolean item="keywordNearStart" value={scoreData.title.keywordNearStart} />
                      <DiagnosticItem item="titleLength" incomplete={scoreData.title.length < 70} warning={scoreData.title.length > 100} complete={scoreData.title.length >= 70 && scoreData.title.length <= 100} border={false} />

                    </div>

                  </div>
                </div>

                {/* Tags score */}
                <div className="flex flex-wrap gap-4">
                  <div className="px-8 py-2 border bg-white mt-2 mb-2 rounded-md w-full sm:w-72 shrink-0 grow flex flex-col">
                    <div className="text-center">
                      <h3 className="text-neutral-700 text-2xl pt-4 pb-2 font-bold" style={{ fontVariant: "all-small-caps" }}>Tags Optimization</h3>
                      <div className="pb-4 pt-2 group text-neutral-600 text-3xl">
                        {ratingToLabel(scoreData.tags.score, 10)}
                      </div>
                      <p className="text-neutral-600 text-sm px-4">Etsy tries to match user queries with keywords in tags. See our <a href='https://salesdoe.com/how-etsy-tags-work' target="_blank" className="underline decoration-dotted text-rose-600">full guide to how Etsy tags work</a> if you want to understand how they fit in the search algorithm.</p>
                    </div>

                    <div>
                      <h4 className="text-neutral-700 text-xl pt-4 pb-2 font-bold" style={{ fontVariant: "all-small-caps" }}>Key Aspects</h4>

                      <DiagnosticItemBoolean item="keywordInTags" value={scoreData.tags.keywordInTags} />
                      {!scoreData.tags.keywordInTags &&
                        <DiagnosticItemBoolean item="keywordInTagsPartial" value={scoreData.tags.keywordInTagsPartial} />
                      }
                      <DiagnosticItemBoolean item="tagsUsed" value={scoreData.tags.tagsUsed == 13} />
                      <DiagnosticItemBoolean item="totalRepeatedWords" value={scoreData.tags.totalRepeatedWords <= 3} border={false} />
                    </div>

                  </div>
                </div>



                {/* Description score */}


                {/* Media score */}
                <div className="flex flex-wrap gap-4">
                  <div className="px-8 py-2 border bg-white mt-2 mb-2 rounded-md w-full sm:w-72 shrink-0 grow flex flex-col">
                    <div className="text-center">
                      <h3 className="text-neutral-700 text-2xl pt-4 pb-2 font-bold" style={{ fontVariant: "all-small-caps" }}>Media Optimization</h3>
                      <div className="pb-4 pt-2 group text-neutral-600 text-3xl">
                        {ratingToLabel(scoreData.media.score, 10)}
                      </div>
                      <p className="text-neutral-600 text-sm px-4">While only your thumbnail affects click through rate from search results for users, images and videos are arguably the most important factor when it comes to converting to a sale. Include as many high quality images that show your product in real use cases as possible.</p>
                    </div>

                    <div>
                      <h4 className="text-neutral-700 text-xl pt-4 pb-2 font-bold" style={{ fontVariant: "all-small-caps" }}>Key Aspects</h4>

                      <DiagnosticItemBoolean item="numberOfImages" value={scoreData.media.numberOfImages > 6} />
                      <DiagnosticItemBoolean item="numberOfVideos" value={scoreData.media.numberOfVideos == 1} border={false} />
                    </div>

                  </div>
                </div>



                {/* Reviews score */}
                <div className="flex flex-wrap gap-4">
                  <div className="px-8 py-2 border bg-white mt-2 mb-2 rounded-md w-full sm:w-72 shrink-0 grow flex flex-col">
                    <div className="text-center">
                      <h3 className="text-neutral-700 text-2xl pt-4 pb-2 font-bold" style={{ fontVariant: "all-small-caps" }}>Reviews</h3>
                      <div className="pb-4 pt-2 group text-neutral-600 text-3xl">
                        {ratingToLabel(scoreData.reviews.score, 5)}
                      </div>
                      <p className="text-neutral-600 text-sm px-4">Etsy reviews are one of the most important factors when it comes to sales. Users look for them before buying, and many sort for recent reviews. While Etsy does prompt previous customers to leave reviews, consider also enouraging reviews through a great packaging experience. See our research on <a href="https://salesdoe.com/five-star-reviews-etsy" target="_blank" className="underline decoration-dotted text-rose-600">the reasons why Etsy customers leave 5 star reviews</a>.</p>
                    </div>

                    <div>
                      <h4 className="text-neutral-700 text-xl pt-4 pb-2 font-bold" style={{ fontVariant: "all-small-caps" }}>Key Aspects</h4>

                      <DiagnosticItem item="reviewCountScore" incomplete={scoreData.reviews.reviewCountScore < 0.6} warning={scoreData.reviews.reviewCountScore < 1.2 && scoreData.reviews.reviewCountScore >= 0.6} complete={scoreData.reviews.reviewCountScore >= 1.2} border={true} />
                      <DiagnosticItem item="reviewRating" incomplete={scoreData.reviews.reviewRating < 3.5} warning={scoreData.reviews.reviewRating < 4.2 && scoreData.reviews.reviewRating >= 3.5} complete={scoreData.reviews.reviewRating >= 4.2} border={true} />
                      <DiagnosticItem item="recentReviews" incomplete={scoreData.reviews.recentReviews == 0} warning={scoreData.reviews.recentReviews < 3 && scoreData.reviews.recentReviews >= 1} complete={scoreData.reviews.recentReviews >= 3} border={false} />

                    </div>

                  </div>
                </div>


              </div>
            }

            {/* {listingData.listing_id > 0 &&
              <div className="flex flex-wrap gap-4">
                <div className="px-8 py-2 border bg-white mt-6 mb-4 rounded-md w-full sm:w-72 shrink-0 grow flex flex-col">
                  <div className="text-rose-500 text-lg pt-4 pb-2" style={{ fontVariant: "all-small-caps" }}>Performance</div>
                  <div className="pb-4 pt-2 group">
                    <div className="text-gray-600 hover:cursor-help">Total Views
                    </div>
                    <div className="text-gray-900 text-xl font-bold">{listingData.views}</div>
                  </div>
                  <hr />
                  <div className="py-4 group">
                    <div className="text-gray-600 hover:cursor-help">Views / Month
                    </div>
                    <div className="text-gray-900 text-xl font-bold">{listingData.viewsPerMonth}</div>
                  </div>
                  <hr />
                  <div className="py-4 group">
                    <div className="text-gray-600 hover:cursor-help">Favorites</div>
                    <div className="text-gray-900 text-xl font-bold">{listingData.num_favorers}</div>
                  </div>
                  <hr />
                  <div className="py-4 group">
                    <div className="text-gray-600 hover:cursor-help">Quantity</div>
                    <div className="text-gray-900 text-xl font-bold">{listingData.quantity}</div>
                  </div>
                </div>

                <div className="px-8 py-2 border bg-white mt-6 mb-4 rounded-md w-full sm:w-72 shrink-0 grow flex flex-col justify-around">
                  <div className="text-rose-500 text-lg pt-4 pb-2" style={{ fontVariant: "all-small-caps" }}>About</div>
                  <div className="pb-4 pt-2 group">
                    <div className="text-gray-600 hover:cursor-help">Created</div>
                    <div className="text-gray-900 text-xl font-bold">{epochToDate(listingData.original_creation_timestamp)}</div>
                  </div>
                  <hr />
                  <div className="py-4 group">
                    <div className="text-gray-600 hover:cursor-help">Last Updated</div>
                    <div className="text-gray-900 text-xl font-bold">{epochToDate(listingData.updated_timestamp)}</div>
                  </div>
                  <hr />
                  <div className="py-4 group">
                    <div className="text-gray-600 hover:cursor-help">Price</div>
                    <div className="text-gray-900 text-xl font-bold">{listingData.priceFormatted}</div>
                  </div>
                  <hr />
                  <div className="py-4 group">
                    <div className="text-gray-600 hover:cursor-help">Processing Time</div>
                    <div className="text-gray-900 text-xl font-bold">{`${listingData.processing_min}-${listingData.processing_max} days`}</div>
                  </div>
                </div>
              </div>
            }

            {listingData.listing_id > 0 &&
              <div className="flex flex-wrap gap-4">
                <div className="px-8 py-2 border bg-white mt-2 mb-2 rounded-md w-full sm:w-72 shrink-0 grow flex flex-col">
                  <div className="text-rose-500 text-lg pt-4 pb-2" style={{ fontVariant: "all-small-caps" }}>Listing Details</div>
                  <div className="pb-4 pt-2 group">
                    <div className="text-gray-600 hover:cursor-help">Title
                    </div>
                    <div className="text-gray-900 text-xl font-bold" dangerouslySetInnerHTML={{ __html: listingData.title }}></div>
                  </div>
                  <hr />
                  <div className="py-4 group">
                    <div className="text-gray-600 hover:cursor-help">Description
                    </div>
                    <div className="text-gray-900 text-xl italic" dangerouslySetInnerHTML={{ __html: listingData.description }}></div>
                  </div>
                  <hr />
                  <div className="py-4 group">
                    <div className="text-gray-600 hover:cursor-help">Tags</div>
                    <div className="flex flex-wrap gap-x-2 gap-y-2 mt-2">
                      {listingData.tags.map(t => <div className="py-1 px-4 my-1 mx-1 border rounded-full bg-blue-50 border-blue-100" key={t}>{t}</div>)}
                    </div>
                  </div>
                  <hr />
                  <div className="py-4 group">
                    <div className="text-gray-600 hover:cursor-help">Taxonomy</div>
                    <div className="text-gray-900 text-xl font-bold">{listingData.taxonomyName}</div>
                  </div>
                </div>
              </div>
            }

            {listingData.listing_id > 0 &&
              <div className="flex flex-wrap justify-between gap-8 mt-4 mb-8 ">
                <div className="p-4 border rounded-md bg-white grow">
                  <h2 className="text-xl text-center pb-2"><a href={listingData.shop.url} target="_blank" className="border-b border-dashed	border-blue-400">{listingData.shop.shop_name} - {listingData.shop.shop_location_country_iso}</a></h2>
                  <div className="text-l flex flex-wrap gap-3 justify-between py-1">
                    <span>Shop title:</span>
                    <span><i>{listingData.shop.title}</i></span>
                  </div>
                  <div className="text-l flex flex-wrap gap-3 justify-between py-1">
                    <span>Created:</span>
                    <span><i>{epochToDate(listingData.shop.created_timestamp)}</i></span>
                  </div>
                </div>

                <div className="p-4 border rounded-md bg-white grow">

                  <div className="text-rose-500 text-lg text-center pb-1" style={{ fontVariant: "all-small-caps" }}>
                    Shop Details
                  </div>
                  <div className="rounded-md bg-white grow flex gap-2 justify-around align-center">

                    <div className="flex flex-col text-center justify-center">
                      <div className="font-bold">Listings</div>
                      <div className="text-xl text-slate-500 py-2">{listingData.shop.listing_active_count}</div>
                    </div>
                    <div className="flex flex-col text-center justify-center">
                      <div className="font-bold">Sales</div>
                      <div className="text-xl text-slate-500 py-2">{listingData.shop.transaction_sold_count}</div>
                    </div>
                    <div className="flex flex-col text-center justify-center">
                      <div className="font-bold">Favorites</div>
                      <div className="text-xl text-slate-500 py-2">{listingData.shop.num_favorers}</div>
                    </div>
                  </div>
                </div>
              </div>
            } */}



          </>

        </MainDashboardContent>
      </MainContent>
      <Footer />
    </>

  )
}

export default ListingOptimizer