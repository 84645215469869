import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useAuthContext } from './hooks/useAuthContext'
import { Helmet } from "react-helmet"

import { ReactComponent as LoadingIcon } from "./assets/analyze.svg";
import SaleSamuraiAlternatives from './pages/BlogPosts/SaleSamuraiAlternatives';


const Home = lazy(() => import('./pages/Home'));
const Success = lazy(() => import('./pages/Success'));
const Signup = lazy(() => import('./pages/Signup'));
const Login = lazy(() => import('./pages/Login'));
const Admin = lazy(() => import('./pages/Admin'));
const Oauth = lazy(() => import('./pages/Oauth'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));
const Help = lazy(() => import('./pages/Help/Help'));
const HelpArticle = lazy(() => import('./pages/Help/HelpArticle'));
const Account = lazy(() => import('./pages/Account'));
const CompetitionOverview = lazy(() => import('./pages/CompetitionOverview/CompetitionOverview'));
const CompetitionAnalytics = lazy(() => import('./pages/CompetitionAnalytics/CompetitionAnalytics'));
const StoreOverview = lazy(() => import('./pages/StoreOverview'));
const TagIntersect = lazy(() => import('./pages/TagIntersect'));
const ListingTracker = lazy(() => import('./pages/ListingTracker'));
const ListingOverview = lazy(() => import('./pages/ListingOverview'));
const ListingOptimizer = lazy(() => import('./pages/ListingOptimizer'));
const KeywordTool = lazy(() => import('./pages/KeywordTool'));
const KeywordOverview = lazy(() => import('./pages/KeywordOverview/KeywordOverview'));
const KeywordResearch = lazy(() => import('./pages/KeywordResearch/KeywordResearch'));
const DescriptionGenerator = lazy(() => import('./pages/DescriptionGenerator'));
const Pricing = lazy(() => import('./pages/Pricing'));
const Blog = lazy(() => import('./pages/Blog'));
const Sensor = lazy(() => import('./pages/Sensor/Sensor'));
const RankChecker = lazy(() => import('./pages/RankChecker'));
const FreeTools = lazy(() => import('./pages/FreeTools'));
const TopShops = lazy(() => import('./pages/TopShops'));
const UseCases = lazy(() => import('./pages/UseCases'));
const WaitingPage = lazy(() => import('./pages/WaitingPage'));
const NotFound = lazy(() => import('./pages/NotFound'));

// Blog posts
const FiveStarReviews = lazy(() => import('./pages/FiveStarReviews'));
const OneStarReviews = lazy(() => import('./pages/OneStarReviews'));
const RequestsError = lazy(() => import('./pages/BlogPosts/RequestsError'));
const ListingsDeactivated = lazy(() => import('./pages/BlogPosts/ListingsDeactivated'));
const CantSaveListing = lazy(() => import('./pages/BlogPosts/CantSaveListing'));
const SeeListingTags = lazy(() => import('./pages/BlogPosts/SeeListingTags'));
const EtsySearchMultipleTags = lazy(() => import('./pages/BlogPosts/EtsySearchMultipleTags'));
const FindGoodTags = lazy(() => import('./pages/BlogPosts/FindGoodTags'));
const HowTagsWork = lazy(() => import('./pages/BlogPosts/HowTagsWork'));
const FavoritesNoSales = lazy(() => import('./pages/BlogPosts/FavoritesNoSales'));
const ExpensiveProducts = lazy(() => import('./pages/BlogPosts/ExpensiveProducts'));
const FirstEtsySale = lazy(() => import('./pages/BlogPosts/FirstEtsySale'));
const LowSales = lazy(() => import('./pages/BlogPosts/LowSales'));
const RespondBadReview = lazy(() => import('./pages/BlogPosts/RespondBadReview'));
const NumberOfListings = lazy(() => import('./pages/BlogPosts/NumberOfListings'));
const RenewListingHelp = lazy(() => import('./pages/BlogPosts/RenewListingHelp'));
const SellSameThing = lazy(() => import('./pages/BlogPosts/SellSameThing'));
const SeeTopProducts = lazy(() => import('./pages/BlogPosts/SeeTopProducts'));
const TrackCompetitors = lazy(() => import('./pages/BlogPosts/TrackCompetitors'));
const MarketResearch = lazy(() => import('./pages/BlogPosts/MarketResearch'));
const NaicsCodes = lazy(() => import('./pages/BlogPosts/NaicsCodes'));
const FirstYearSales = lazy(() => import('./pages/BlogPosts/FirstYearSales'));
const LongTailKws = lazy(() => import('./pages/BlogPosts/LongTailKws'));
const SalesDoeVsErank = lazy(() => import('./pages/BlogPosts/SalesDoeVsErank'));

const ErankAlternatives = lazy(() => import('./pages/BlogPosts/ErankAlternatives'));
const MarmaleadAlternatives = lazy(() => import('./pages/BlogPosts/MarmaleadAlternatives'));
const AluraAlternatives = lazy(() => import('./pages/BlogPosts/AluraAlternatives'));
const EtsyHuntAlternatives = lazy(() => import('./pages/BlogPosts/EtsyHuntAlternatives'));
const TrackNewLaunches = lazy(() => import('./pages/BlogPosts/TrackNewLaunches'));
const SeeHowMuchEtsyShopMakes = lazy(() => import('./pages/BlogPosts/SeeHowMuchEtsyShopMakes'));
const SeeEtsyShopsListings = lazy(() => import('./pages/BlogPosts/SeeEtsyShopsListings'));


function App() {
  const { user } = useAuthContext()

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <div>error</div>,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/site-builder",
      element: <WaitingPage />,
    },
    {
      path: "/oauth",
      element: <Oauth />,
    },
    {
      path: "/success",
      element: <Success />,
    },
    {
      path: "/login",
      element: !user ? <Login /> : <Navigate to="/account" />,
    },
    {
      path: "/superadminpanel",
      element: !user ? <Navigate to="/login" /> : <Admin />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password/:token",
      element: <ResetPassword />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/terms",
      element: <Terms />,
    },
    {
      path: "/help-center",
      element: <Help />,
    },
    {
      path: "/help-center/:helpSlug",
      element: <HelpArticle />,
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "/keyword-tool",
      element: user ? <KeywordTool /> : <Navigate to="/login" />,
    },
    {
      path: "/keyword-overview",
      element: user ? <KeywordOverview /> : <Navigate to="/login" />,
    },
    {
      path: "/keyword-research",
      element: user ? <KeywordResearch /> : <Navigate to="/login" />,
    },
    {
      path: "/description-generator",
      element: <DescriptionGenerator />,
    },
    {
      path: "/sensor",
      element: <Sensor />,
    },
    {
      path: "/etsy-rank-checker",
      element: <RankChecker />,
    },
    {
      path: "/features/competition-monitoring",
      element: <UseCases />,
    },
    {
      path: "/competition",
      element: user ? <CompetitionOverview /> : <Navigate to="/login" />,
    },
    {
      path: "/competition/settings",
      element: user ? <CompetitionOverview /> : <Navigate to="/login" />,
    },
    {
      path: "/competition/analytics",
      element: user ? <CompetitionAnalytics /> : <Navigate to="/login" />,
    },
    {
      path: "/shop-overview",
      element: <StoreOverview />,
    },
    {
      path: "/tools/shop-overview",
      element: <Navigate to="/shop-overview" />
    },
    {
      path: "/listing-overview",
      element: user ? <ListingOverview /> : <Navigate to="/login" />,
    },
    {
      path: "/tag-intersect",
      element: user ? <TagIntersect /> : <Navigate to="/login" />,
    },
    {
      path: "/listing-tracker",
      element: user ? <ListingTracker /> : <Navigate to="/login" />,
    },
    {
      path: "/listing-optimizer",
      element: user ? <ListingOptimizer /> : <Navigate to="/login" />,
    },
    {
      path: "/account",
      element: user ? <Account /> : <Navigate to="/login" />,
    },
    {
      path: "/top-etsy-shops",
      element: <TopShops />,
    },
    {
      path: "/blog",
      element: <Blog />,
    },
    {
      path: "/five-star-reviews-etsy",
      element: <FiveStarReviews />,
    },
    {
      path: "/one-star-reviews-etsy",
      element: <OneStarReviews />,
    },
    {
      path: "/429-error-etsy",
      element: <RequestsError />,
    },
    {
      path: "/etsy-deactivate-listing",
      element: <ListingsDeactivated />,
    },
    {
      path: "/etsy-cant-save-draft-listing",
      element: <CantSaveListing />,
    },
    {
      path: "/erank-alternatives",
      element: <ErankAlternatives />,
    },
    {
      path: "/marmalead-alternatives",
      element: <MarmaleadAlternatives />,
    },
    {
      path: "/etsy-hunt-alternatives",
      element: <EtsyHuntAlternatives />,
    },
    {
      path: "/alura-alternatives",
      element: <AluraAlternatives />,
    },
    {
      path: "/sale-samurai-alternatives",
      element: <SaleSamuraiAlternatives />,
    },
    {
      path: "/track-new-launches",
      element: <TrackNewLaunches />,
    },
    {
      path: "/see-how-much-etsy-shop-makes",
      element: <SeeHowMuchEtsyShopMakes />,
    },
    {
      path: "/see-etsy-shops-listings",
      element: <SeeEtsyShopsListings />,
    },
    {
      path: "/how-to-look-at-tags-etsy-listing",
      element: <SeeListingTags />,
    },
    {
      path: "/etsy-search-multiple-tags",
      element: <EtsySearchMultipleTags />,
    },
    {
      path: "/find-good-etsy-tags",
      element: <FindGoodTags />,
    },
    {
      path: "/how-etsy-tags-work",
      element: <HowTagsWork />,
    },
    {
      path: "/etsy-favorites-but-no-sales",
      element: <FavoritesNoSales />,
    },
    {
      path: "/selling-expensive-products-etsy",
      element: <ExpensiveProducts />,
    },
    {
      path: "/first-etsy-sale",
      element: <FirstEtsySale />,
    },
    {
      path: "/low-etsy-sales",
      element: <LowSales />,
    },
    {
      path: "/respond-remove-bad-etsy-reviews",
      element: <RespondBadReview />,
    },
    {
      path: "/how-many-listings-etsy",
      element: <NumberOfListings />,
    },
    {
      path: "/does-renewing-etsy-listing-help",
      element: <RenewListingHelp />,
    },
    {
      path: "/sell-same-thing-etsy",
      element: <SellSameThing />,
    },
    {
      path: "/see-competitors-top-products-etsy",
      element: <SeeTopProducts />,
    },
    {
      path: "/track-competitors-etsy",
      element: <TrackCompetitors />,
    },
    {
      path: "/market-research-etsy",
      element: <MarketResearch />,
    },
    {
      path: "/naics-codes-etsy-sellers",
      element: <NaicsCodes />,
    },
    {
      path: "/free-seo-tools-etsy",
      element: <FreeTools />,
    },
    {
      path: "/average-etsy-sales-first-year",
      element: <FirstYearSales />,
    },
    {
      path: "/find-long-tail-keywords-etsy",
      element: <LongTailKws />,
    },
    {
      path: "/salesdoe-vs-erank-vs-marmalead",
      element: <SalesDoeVsErank />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <div className="App h-full flex flex-col">
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-PYQ7MPNGDZ"></script>
        <script>
          {
            `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            ${user && user?.id > 0 ?
              `gtag('config', 'G-PYQ7MPNGDZ', {
                'user_id': 00032${user.id}
              });
              dataLayer.push({ 'userId': ${user.id} });
              dataLayer.push({event: 'user-data-set'})
            `
              :
              "gtag('config', 'G-PYQ7MPNGDZ');"
            }
            `
          }
        </script>



      </Helmet>
      <Suspense fallback={<LoadingFallback />}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
}


const LoadingFallback = () => {
  return (
    <div className='w-full h-full flex justify-center items-center'>

      <div className='mb-12'>
        <div className='flex justify-center mb-4'>
          <LoadingIcon className='animate-spin' />
        </div>
        <div className='text-xl'>Loading...</div>
      </div>

    </div>
  )

}


export default App;
