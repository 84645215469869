import { PlanContext } from "../context/PlanContext"
import { useContext } from "react"

export const usePlanContext = () => {
  const context = useContext(PlanContext)

  if(!context) {
    throw Error('usePlanContext must be used inside an PlanContextProvider')
  }

  return context
}